import React from 'react';
import { graphql } from 'gatsby';

import Button from '../components/shared/Button';
import Header from '../components/Header';
import Layout from '../components/Layout';

import PropTypes from 'prop-types'
import { richTextParse } from '../utils/utility-functions';

import computer from '../assets/graphics/flatwave-computer-and-pencil-case.svg';
import delimiter2 from '../assets/graphics/flatwave-delimiter-2.png';
import employee from '../assets/graphics/flatwave-employee-id-card.svg';
import delimiter3 from '../assets/graphics/flatwave-delimiter-3.png';
import compass from '../assets/graphics/flatwave-compass.svg';
import notebook from '../assets/graphics/flatwave-notebook.svg';

const Top = ({ topContent, topButton }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12-sm col-8 center-sm">
          { richTextParse(topContent[0]) }
          <Button
            linkType={ topButton.linkType }
            link={ topButton.link }
            text={ topButton.text }
            color={ topButton.color }
          />
        </div>
      </div>
      <img className="computer hidden-sm" src={ computer } alt={ "Computer" } />
    </div>
  )
};

const Middle = ({ middleContent, middleButton }) => {
  return (
    <div className="middle">
      <div className="waves waves--mirrored"></div>
      <img className="delimiter-top" src={ delimiter2 } alt={ "Flatwave delimiter" } />
      <div className="container">
        <div className="row">
          <div className="col-4 hidden-sm"></div>
          <div className="col-12-sm col-8 center-sm contentful-white">
            { richTextParse(middleContent[0]) }
            <Button
              linkType={ middleButton.linkType }
              link={ middleButton.link }
              text={ middleButton.text }
              color={ middleButton.color }
            />
          </div>
        </div>
        <img className="employee hidden-sm" src={ employee } alt={ "Employee ID card" } />
      </div>
      <img className="delimiter-bottom" src={ delimiter3 } alt={ "Flatwave delimiter" } />
    </div>
  )
};

const Bottom = ({ bottomContent, bottomButton }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12-sm col-8 center-sm">
          { richTextParse(bottomContent[0]) }
          <Button
            linkType={ bottomButton.linkType }
            link={ bottomButton.link }
            text={ bottomButton.text }
            color={ bottomButton.color }
          />
        </div>
        <div className="col-4 hidden-sm"></div>
      </div>
      <img className="compass hidden-sm" src={ compass } alt={ "Compass" } />
      <img className="notebook hidden-sm" src={ notebook } alt={ "Notebook" } />
    </div>
  )
};

const IndexPage = ({ data }) => (
  <Layout>
    <div>
      <Header
        title={ data.contentfulIndexContent.title }
        subtitle={ data.contentfulIndexContent.description }
      />
      <Top
        topContent={ data.allContentfulIndexContentSectionTopRichTextNode.nodes }
        topButton={ data.contentfulIndexContentButtonTopSectionJsonNode }
      />
      <Middle
        middleContent={ data.allContentfulIndexContentSectionMiddleRichTextNode.nodes }
        middleButton={ data.contentfulIndexContentButtonMiddleSectionJsonNode }
      />
      <Bottom
        bottomContent={ data.allContentfulIndexContentSectionBottomRichTextNode.nodes }
        bottomButton={ data.contentfulIndexContentButtonBottomSectionJsonNode }
      />
    </div>
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

export const indexPageQuery = graphql`
query IndexPageQuery {
  contentfulIndexContent {
    title
    description
  }
  allContentfulIndexContentSectionTopRichTextNode {
    nodes {
      nodeType
      content {
        nodeType
        content {
          value
          nodeType
          marks {
            type
          }
        }
      }
    }
  }
  contentfulIndexContentButtonTopSectionJsonNode {
    text
    linkType
    link
    color
  }
  allContentfulIndexContentSectionMiddleRichTextNode {
    nodes {
      nodeType
      content {
        nodeType
        content {
          value
          nodeType
          marks {
            type
          }
        }
      }
    }
  }
  contentfulIndexContentButtonMiddleSectionJsonNode {
    text
    linkType
    link
    color
  }
  allContentfulIndexContentSectionBottomRichTextNode {
    nodes {
      nodeType
      content {
        nodeType
        content {
          value
          nodeType
          marks {
            type
          }
        }
      }
    }
  }
  contentfulIndexContentButtonBottomSectionJsonNode {
    text
    linkType
    link
    color
  }
}

`;
